import React from "react"
import classNames from "classnames"
import { SectionSplitProps } from "utils/SectionProps"
import SectionHeader from "components/layout/partials/SectionHeader"
import Image from "components/elements/Image"

import multi_stage_image from "assets/images/multi_stage.png"
import paint_instances_tool_image from "assets/images/paint_instances_tool.png"
import shading_image from "assets/images/shading.png"
import soft_selection_image from "assets/images/soft_selection.png"
import paint_primvar_tool_image from "assets/images/paint_primvar_tool.png"
import blender_live_share_image from "assets/images/blender_live_share.png"
import dcc_live_share_image from "assets/images/dcc_live_share.png"
import usd_editing_image from "assets/images/usd_editing.png"
import modeling_instances_image from "assets/images/modeling_instances.png"
import animx_image from "assets/images/graph_editor.png"


import LayoutDefault from "layouts/LayoutDefault"
import SolutionFeaturesTiles from "components/layout/en/SolutionFeaturesTiles"
import Cta from "components/layout/en/Cta"
import ModalVideo from "components/elements/ModalVideo"
import ButtonGroup from "components/elements/ButtonGroup"
import RequestAccessButton from "components/layout/RequestAccessButton"

const propTypes = {
  ...SectionSplitProps.types,
}

const defaultProps = {
  ...SectionSplitProps.defaults,
}

const Index = ({
  className,
  topOuterDivider,
  bottomOuterDivider,
  topDivider,
  bottomDivider,
  hasBgColor,
  invertColor,
  invertMobile,
  invertDesktop,
  alignTop,
  imageFill,
  ...props
}) => {
  const outerClasses = classNames(
    "features-split section",
    topOuterDivider && "has-top-divider",
    bottomOuterDivider && "has-bottom-divider",
    hasBgColor && "has-bg-color",
    invertColor && "invert-color",
    className
  )

  const innerClasses = classNames(
    "features-split-inner section-inner",
    topDivider && "has-top-divider",
    bottomDivider && "has-bottom-divider"
  )

  const splitClasses = classNames(
    "split-wrap",
    invertMobile && "invert-mobile",
    invertDesktop && "invert-desktop",
    alignTop && "align-top"
  )

  const sectionHeader = {
    title: [
      <>
       Wizart <span className="text-color-primary">DCC</span> Platform <br/>
       for Scene <span className="text-color-primary">Assembly</span>
      </>
    ],
    paragraph:
      <>  <span className="text-color-primary">WSYWIG</span> edit your pipeline data without hiccup </>
  }

  return (
    <LayoutDefault {...props}>
      <section {...props} className={outerClasses}>
        <div className="container">
          <div className={innerClasses}>
          <SectionHeader
              data={sectionHeader}
              className="center-content"
              style={{
                paddingBottom: 32,
              }}
            />
            <div className="center-content">
              <ButtonGroup>
                <RequestAccessButton name={"Request Access"} />
              </ButtonGroup>
            </div>
            <div className={splitClasses}>
            <SolutionFeaturesTiles/>

             
            <div className="split-item">
                <div
                  className="split-item-content center-content-mobile reveal-from-left"
                  data-reveal-container=".split-item"
                >
                  <div className="text-xxs text-color-primary fw-600 tt-u mb-8">
                  </div>
                  <h3 className="mt-0 mb-12">USD editing</h3>
                  <p className="m-0">
                    Wizart DCC supports industry standard manipulators that authors <b>USDXform</b> attributes. Supported features like various snapping modes and edit pivot.
                    You can group, duplicate and rename prims.
                    We draw visual locators for <b>USDLux</b> and <b>UsdCamera</b> prim types, that you could be often missing in <b>usdview</b>.
                  </p>
                </div>
                <div
                  className={classNames(
                    "split-item-image center-content-mobile reveal-from-bottom",
                    imageFill && "split-item-image-fill"
                  )}
                  data-reveal-container=".split-item"
                >
                  <ModalVideo
                    videoId="-UeXXgjN7yY"
                    width={528}
                    height={396}
                    thumbnailSrc = {usd_editing_image}
                  />
                </div>
              </div>

              <div className="split-item">
                <div
                  className="split-item-content center-content-mobile reveal-from-left"
                  data-reveal-container=".split-item"
                >
                  <div className="text-xxs text-color-primary fw-600 tt-u mb-8">
                  </div>
                  <h3 className="mt-0 mb-12">Multi USD Stage workflows</h3>
                  <p className="m-0">
                  Wizart DCC allows you load to the session multiple USD Stages simultaneously and switch between them at any moment. It's handy if you want to edit asset data in shot context or neighbor shots.
                  </p>
                </div>
                <div
                  className={classNames(
                    "split-item-image center-content-mobile reveal-from-bottom",
                    imageFill && "split-item-image-fill"
                  )}
                  data-reveal-container=".split-item"
                >
                  <ModalVideo
                    videoId="HH83TocCq48"
                    width={528}
                    height={396}
                    thumbnailSrc = {multi_stage_image}
                  />
                </div>
              </div>


              <div className="split-item">
                <div
                  className="split-item-content center-content-mobile reveal-from-left"
                  data-reveal-container=".split-item"
                >
                  <div className="text-xxs text-color-primary fw-600 tt-u mb-8">
                  </div>
                  <h3 className="mt-0 mb-12">Modeling</h3>
                  <p className="m-0">
                  Compared to the other USD editors, except prim selection, we also support selecting points, edges, and faces, including <b>Soft Selection</b> mode. Using manipulators you can directly modify <b>UsdGeom.points</b> attribute, based on current selection, without complex import/export workflows.
                  </p>
                </div>
                <div
                  className={classNames(
                    "split-item-image center-content-mobile reveal-from-bottom",
                    imageFill && "split-item-image-fill"
                  )}
                  data-reveal-container=".split-item"
                >
                  <ModalVideo
                    videoId="cPH9TPkXvJg"
                    width={528}
                    height={396}
                    thumbnailSrc = {soft_selection_image}
                  />
                </div>
              </div>
              

              <div className="split-item">
                <div
                  className="split-item-content center-content-mobile reveal-from-left"
                  data-reveal-container=".split-item"
                >
                  <div className="text-xxs text-color-primary fw-600 tt-u mb-8">
                    In development
                  </div>
                  <h3 className="mt-0 mb-12">USD Live Share for Modeling </h3>
                  <p className="m-0">
                    Using the <b>Live Share</b> function, we can use rich third-party modeling and sculpting apps to send live edits between different processes. For example between <b>Blender</b> and <b>Wizart DCC</b>.
                  </p>
                </div>
                <div
                  className={classNames(
                    "split-item-image center-content-mobile reveal-from-bottom",
                    imageFill && "split-item-image-fill"
                  )}
                  data-reveal-container=".split-item"
                >
                  <ModalVideo
                    videoId="q2tp1D4R7zY"
                    width={528}
                    height={396}
                    thumbnailSrc = {blender_live_share_image}
                  />
                </div>
              </div>
              
              <div className="split-item">
                <div
                  className="split-item-content center-content-mobile reveal-from-left"
                  data-reveal-container=".split-item"
                >
                  <div className="text-xxs text-color-primary fw-600 tt-u mb-8">
                  </div>
                  <h3 className="mt-0 mb-12">Paint Instances Tool</h3>
                  <p className="m-0">
                    Tool for interactive editing Point Instancer prim point cloud using the brushes.
                  </p>
                </div>
                <div
                  className={classNames(
                    "split-item-image center-content-mobile reveal-from-bottom",
                    imageFill && "split-item-image-fill"
                  )}
                  data-reveal-container=".split-item"
                >
                  <ModalVideo
                    videoId="7AWoBhEs9j0"
                    width={528}
                    height={396}
                    thumbnailSrc = {paint_instances_tool_image}
                  />
                </div>
              </div>


              <div className="split-item">
                <div
                  className="split-item-content center-content-mobile reveal-from-left"
                  data-reveal-container=".split-item"
                >
                  <div className="text-xxs text-color-primary fw-600 tt-u mb-8">
                  </div>
                  <h3 className="mt-0 mb-12">Paint Primvar Tool</h3>
                  <p className="m-0">
                    The tool for editing Primvar attributes using the brush.
                  </p>
                </div>
                <div
                  className={classNames(
                    "split-item-image center-content-mobile reveal-from-bottom",
                    imageFill && "split-item-image-fill"
                  )}
                  data-reveal-container=".split-item"
                >
                  <ModalVideo
                    videoId="4Rq1J3zPUvw"
                    width={528}
                    height={396}
                    thumbnailSrc = {paint_primvar_tool_image}
                  />
                </div>
              </div>
              
              <div className="split-item">
                <div
                  className="split-item-content center-content-mobile reveal-from-left"
                  data-reveal-container=".split-item"
                >
                  <div className="text-xxs text-color-primary fw-600 tt-u mb-8">
                  </div>
                  <h3 className="mt-0 mb-12">Point Instancer Modeling</h3>
                  <p className="m-0">
                    We also support selection of separate instances in Point Instancer.
                    Plus you can directly edit Point Instancer prim point cloud using the manipulators.
                  </p>
                </div>
                <div
                  className={classNames(
                    "split-item-image center-content-mobile reveal-from-bottom",
                    imageFill && "split-item-image-fill"
                  )}
                  data-reveal-container=".split-item"
                >
                  <ModalVideo
                    videoId="rSJe9CCIJno"
                    width={528}
                    height={396}
                    thumbnailSrc = {modeling_instances_image}
                  />
                </div>
              </div>
              
              <div className="split-item">
                <div
                  className="split-item-content center-content-mobile reveal-from-left"
                  data-reveal-container=".split-item"
                >
                  {/* <div className="text-xxs text-color-primary fw-600 tt-u mb-8">
                    Open Source Animation
                  </div> */}
                  <h3 className="mt-0 mb-12">AnimX</h3>
                  <p className="m-0">
                  You can author animation using <a href="https://github.com/Autodesk/animx">AnimX</a> compatible animation curves animation engine. 
                  Using Graph Editor, so you can edit animation right inside the app.
                  </p>
                </div>
                <div
                  className={classNames(
                    "split-item-image center-content-mobile reveal-from-bottom",
                    imageFill && "split-item-image-fill"
                  )}
                  data-reveal-container=".split-item"
                >
                  <ModalVideo
                    videoId="Kh_Epd-UPDU"
                    width={528}
                    height={396}
                    thumbnailSrc = {animx_image}
                  />
                </div>
              </div>

            </div>
          </div>
        </div>
      </section>
      <Cta split topDivider />
    </LayoutDefault>
  )
}

Index.propTypes = propTypes
Index.defaultProps = defaultProps

export default Index